(function(window) {
  var toTop = document.getElementById("back-top");
  var form = document.getElementById("contact-form");
  var formMessages = document.getElementById("returnmessage");
  var showproducts = document.querySelector(".showproducts");
  var prodTable = document.querySelector(".productstable");
  var formElements = Array.from(form.elements);
  var formLoadTime = new Date().getTime();

  function serializeForm(form) {
    var formData = [];
    for (var i = 0; i < form.elements.length; i++) {
      var field = form.elements[i];
      if (!field.name || field.disabled || field.type === "file" || field.type === "reset" || field.type === "submit" || field.type === "button") continue;
      if (field.type === "select-multiple") {
        for (var j = 0; j < field.options.length; j++) {
          if (field.options[j].selected) {
            formData.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value));
          }
        }
      } else if ((field.type !== "checkbox" && field.type !== "radio") || field.checked) {
        formData.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
      }
    }
    return formData.join("&");    
  }

  form.addEventListener("submit", function name(e) {
    e.preventDefault();

    var formSubmitTime = new Date().getTime();
    var timeDifference = formSubmitTime - formLoadTime;
    if (e.target.elements['honeypot'].value || timeDifference < 3000) {
      return false;
    }

    var data = serializeForm(e.target);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", form.action);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.send(data);
  
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        var response = xhr.responseText;
        if (xhr.status === 200) {
          formMessages.classList.remove("error");
			    formMessages.classList.add("success");
          formElements.forEach(function(element) {
            element.value = "";
          });
        } else {
          formMessages.classList.add("error");
			    formMessages.classList.remove("success");
        }
        formMessages.innerText = response;
      }
    }
  });

  showproducts.addEventListener("click", function () {
    if (prodTable.classList.contains("toggle-hidden")) {
      prodTable.classList.remove("toggle-hidden");
      prodTable.classList.add("toggle-visible");
      prodTable.setAttribute("aria-hidden", "false");
    } else {
      prodTable.classList.remove("toggle-visible");
      prodTable.classList.add("toggle-hidden");
      prodTable.setAttribute("aria-hidden", "true");
    }
  });

  window.addEventListener("scroll", function() {
    var scrollDistance = (typeof window.pageYOffset !== "undefined") ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (scrollDistance > 500) {
      toTop.classList.add("visible");
      toTop.setAttribute("aria-hidden", "false");
    } else {
      toTop.classList.remove("visible");
      toTop.setAttribute("aria-hidden", "true");
    }
  });
})(window);